import { useMutation, useQuery, useReactiveVar } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState } from "react";
import {
  getOneReservation,
  getOneReservationVariables,
} from "../__generated__/getOneReservation";
import { GET_RESERVATION } from "../components/header";
import { useForm } from "react-hook-form";
import {
  LOCALSTORAGE_WHAT_RESERVATION,
  LOCALSTORAGE_WHAT_RESTAURANT,
} from "../constants";
import { reservationVar } from "../apollo";
import { SAMPLE_IMAGE_2 } from "../images/images";
import { ReservationState } from "../__generated__/globalTypes";
import { customTextState } from "../pages/owners/dashboard/que-reservations";
import {
  updateReservation,
  updateReservationVariables,
} from "../__generated__/updateReservation";
import { UPDATE_RESERVATION_MUTATION } from "../pages/owners/dashboard/que-reservations";

interface IFormInput {
  phoneNumber: string;
}

export const Reservation: React.FC = () => {
  const prevPhone = localStorage.getItem("LOCALSTORAGE_PHONE");
  const [phone, setPhone] = useState(prevPhone);
  const isOnReservation = useReactiveVar(reservationVar);

  const onCompleted = () => {
    console.log("isfetched");
    if (data?.getOneReservation.reservation && data.getOneReservation.ok) {
      localStorage.setItem(
        LOCALSTORAGE_WHAT_RESERVATION,
        data.getOneReservation.reservation.id
      );
      reservationVar(data.getOneReservation.reservation.id);
      localStorage.setItem(
        "LOCALSTORAGE_PHONE",
        data.getOneReservation.reservation.phoneNumber
      );
    }
    console.log(data?.getOneReservation.reservation);
  };

  const { data, loading, refetch } = useQuery<
    getOneReservation,
    getOneReservationVariables
  >(GET_RESERVATION, {
    variables: {
      input: {
        phoneNumber: phone,
      },
    },
    skip: isOnReservation === "",
    onCompleted,
  });

  const onUpdateCompleted = () => {
    refetch();
  };

  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInput>({
    mode: "onSubmit",
  });

  const onsubmit = () => {
    const { phoneNumber } = getValues();
    setPhone(phoneNumber);
    console.log(phoneNumber);
    refetch();
  };

  const [updateReservation, { loading: updateLoading }] = useMutation<
    updateReservation,
    updateReservationVariables
  >(UPDATE_RESERVATION_MUTATION);

  const updateButton = (state: ReservationState) => {
    updateReservation({
      variables: {
        input: {
          id: isOnReservation!,
          state,
          restaurantId:
            localStorage.getItem(LOCALSTORAGE_WHAT_RESTAURANT) ?? "",
        },
      },
    });
  };

  console.log(loading, data);
  console.log(errors);

  return (
    <div
      className="pt-20 h-screen w-screen flex flex-col items-center  bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${SAMPLE_IMAGE_2})` }}
    >
      <form
        className="items-center flex flex-col w-full px-8 py-4"
        onSubmit={handleSubmit(onsubmit)}
      >
        <label className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
          전화번호를 입력하세요.
        </label>
        <input
          className="p-2 text-gray-900 w-full block"
          defaultValue={prevPhone ?? "010"}
          type="number"
          {...register("phoneNumber", {
            required: true,
            minLength: 10,
            maxLength: 11,
          })}
        />
        <input className="w-full bg-coolGray-600" type="submit" />
      </form>
      <div className="items-center flex flex-col w-full h-1/2 p-8">
        {errors === {} && <div>핸드폰 번호가 맞지 않습니다.</div>}
        {!loading && data?.getOneReservation.reservation && (
          <div className="w-full h-full bg-gradient-to-br to-yellow-400 via-rose-600 from-blue-500 text-black font-black text-2xl">
            <div className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
              예약번호 :{" "}
              {parseInt(data?.getOneReservation.reservation?.id!) % 100}
            </div>
            <div className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
              예약 상태 :{" "}
              {customTextState(data?.getOneReservation.reservation?.state!)}
            </div>
            <div className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
              예약 경과 시간 :{data?.getOneReservation.reservation?.id!} 분
            </div>
            <div className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
              {data.getOneReservation.reservation.state ===
              ReservationState.Created
                ? "현장예약은 알람을 받을 수 없습니다. 알림을 받고싶은 경우 카운터에 문의하세요"
                : data.getOneReservation.reservation.state ===
                  ReservationState.Listening
                ? "알림 대기가 완료되었습니다. 순번이 올 경우 알림이 옵니다."
                : data.getOneReservation.reservation.state ===
                  ReservationState.Alarmed
                ? "순번이 다가왔습니다! 무명으로 어서오세요"
                : "예약이 완료된 건입니다."}
            </div>
            {data.getOneReservation.reservation.state ===
              ReservationState.Created && (
              <button
                onClick={() => updateButton(ReservationState.Listening)}
                className=" w-full border-b-2 pl-1 mt-12 mb-2 bg-trueGray-400 border-blue-300 text-xl h-10"
              >
                알람을 받고 싶어요 ㅠ
              </button>
            )}
            {data.getOneReservation.reservation.state ===
              ReservationState.Alarmed && (
              <button
                onClick={() => updateButton(ReservationState.Confirmed)}
                className=" w-full border-b-2 pl-1 mt-12 mb-2 bg-trueGray-400 border-blue-300 text-xl h-10"
              >
                알람을 확인했습니다!
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
