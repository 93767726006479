import React from "react";

import { useHistory } from "react-router-dom";
import { isGuestVar, whatRestaurantVar } from "../apollo";
import { LOCALSTORAGE_WHAT_RESTAURANT } from "../constants";
import { SAMPLE_IMAGE, SAMPLE_IMAGE_2 } from "../images/images";
import moomyungLogo from "../images/logo.png";

export const Restaurants = () => {
  const history = useHistory();

  return (
    <div className="h-screen bg-moomyung-50  font-black ">
      <div className=" h-1/6 flex flex-col justify-center">
        <div
          onClick={() => {
            history.push("/login");
          }}
          className="h-1/6 text-white text-right pr-4 pt-2"
        >
          Admin login
        </div>
        <img
          className="object-contain h-5/6 md:w-auto pb-4"
          src={moomyungLogo}
          alt="Bistro Moomyung"
        />
      </div>
      <div className="h-5/6 lg:grid-cols-2 text-gray-100 text-3xl">
        <div className="h-1/2 border-4 border-red-700">
          <img
            onClick={() => {
              localStorage.setItem(LOCALSTORAGE_WHAT_RESTAURANT, "1");
              whatRestaurantVar("1");
              history.push("/about");
            }}
            className="object-cover h-5/6 w-full "
            src={SAMPLE_IMAGE}
            alt="Bistro Moomyung"
          />
          <div className="h-1/6 w-full p-3"> Bistro</div>
        </div>
        <div className=" h-1/2 border-4 border-blue-900">
          <div className="h-1/6 w-full text-right p-3 ">Coffee & Cake</div>
          <img
            onClick={() => {
              localStorage.setItem(LOCALSTORAGE_WHAT_RESTAURANT, "1");
              whatRestaurantVar("1");
              history.push("/about");
            }}
            className="object-cover h-5/6 w-full"
            src={SAMPLE_IMAGE_2}
            alt="Bistro Moomyung"
          />
        </div>
      </div>
    </div>
  );
};
