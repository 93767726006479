import { gql, useQuery, useReactiveVar } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { reservationVar, whatRestaurantVar } from "../apollo";
import {
  LOCALSTORAGE_WHAT_RESERVATION,
  LOCALSTORAGE_WHAT_RESTAURANT,
} from "../constants";
import moomyungLogo from "../images/logo.png";
import {
  getOneReservation,
  getOneReservationVariables,
} from "../__generated__/getOneReservation";
import { ReservationState } from "../__generated__/globalTypes";
import { subUpdatedReservations } from "../__generated__/subUpdatedReservations";
// import { LOCALSTORAGE_TOKEN } from "../constants";
// import { useMe } from "../hooks/useMe";

const ORDER_SUBSCRIPTION = gql`
  subscription subUpdatedReservations($input: String!) {
    subUpdatedReservations(input: $input) {
      id
      state
      createdTime
      phoneNumber
    }
  }
`;

export const GET_RESERVATION = gql`
  query getOneReservation($input: GetReservationInput!) {
    getOneReservation(input: $input) {
      ok
      error
      reservation {
        id
        state
        createdTime
        phoneNumber
      }
    }
  }
`;

export const Header: React.FC = () => {
  const reservationId = useReactiveVar(reservationVar);

  const { data, loading, subscribeToMore } = useQuery<
    getOneReservation,
    getOneReservationVariables
  >(GET_RESERVATION, {
    variables: { input: { id: reservationId } },
  });

  useEffect(() => {
    if (data?.getOneReservation.ok) {
      subscribeToMore({
        document: ORDER_SUBSCRIPTION,
        variables: {
          input: reservationId,
        },
        updateQuery: (
          prev,
          {
            subscriptionData: { data },
          }: { subscriptionData: { data: subUpdatedReservations } }
        ) => {
          if (data === undefined) return prev;
          const updatedReservation = data.subUpdatedReservations;
          return Object.assign({}, prev, {
            reservation: {
              ...updatedReservation,
            },
          });
        },
      });
    }
  });

  if (data?.getOneReservation.reservation?.state === ReservationState.Done) {
    localStorage.setItem(LOCALSTORAGE_WHAT_RESERVATION, "");
    reservationVar("");
  }

  console.log(data?.getOneReservation.reservation);

  const history = useHistory();

  return (
    <div className="p-4 h-20 w-screen bg-moomyung-50 fixed flex justify-between items-center font-black text-xl">
      <div
        onClick={() => {
          history.push("/");
          localStorage.setItem(LOCALSTORAGE_WHAT_RESTAURANT, "");
          whatRestaurantVar("");
        }}
        className="h-12 w-36 bg-contain bg-no-repeat"
        style={{ backgroundImage: `url(${moomyungLogo})` }}
      />
      <div className="">
        <Link className="pr-4" to={`/about`}>
          About
        </Link>
        <Link className="pr-4" to={`/menus`}>
          Menus
        </Link>
        <Link className="" to={`/reservation`}>
          {!loading &&
            data?.getOneReservation.reservation?.state === "Alarmed" && (
              <span className="animate-ping h-10 w-10  bg-red-500 absolute rounded-full" />
            )}
          {!loading &&
            data?.getOneReservation.reservation?.state === "Listening" && (
              <span className="border animate-pulse h-3 w-4 bg-yellow-300 absolute rounded-lg " />
            )}
          <span>Reserve</span>
        </Link>
      </div>
    </div>
  );
};
