import React from "react";
import { Link, useHistory } from "react-router-dom";
import { LOCALSTORAGE_TOKEN } from "../../constants";
import { isLoggedInVar } from "../../apollo";

export const Roles: React.FC = () => {
  const history = useHistory();
  return (
    <div className="h-screen w-screen bg-moomyung-50 flex items-center justify-center">
      <Link
        className="flex-1 bg-gray-500  text-center text-8xl"
        to="/dashboard"
      >
        대쉬보드
      </Link>
      <Link
        className="flex-1 bg-gray-500 text-center text-8xl"
        to="/reservation"
      >
        현장예약
      </Link>
      <button
        className="flex-1 bg-gray-500 text-center text-8xl"
        onClick={() => {
          localStorage.setItem(LOCALSTORAGE_TOKEN, "");
          history.push("/");
          isLoggedInVar(false);
        }}
      >
        ddd
      </button>
    </div>
  );
};
