import { useReactiveVar } from "@apollo/client";
import React from "react";
import { isGuestVar, isLoggedInVar, whatRestaurantVar } from "./apollo";
import { GuestRouter } from "./routers/guest-router";
import { LoggedInRouter } from "./routers/logged-in-router";
import { LoggedOutRouter } from "./routers/logged-out-router";

function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  const restaurant = useReactiveVar(whatRestaurantVar);

  if (isLoggedIn) return <LoggedInRouter />;
  if (restaurant) return <GuestRouter />;
  return <LoggedOutRouter />;
}

export default App;
