import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Header } from "../components/header";
import { Home } from "../guests/home";
import { Menus } from "../guests/menus";
import { Reservation } from "../guests/reservation";
import { NotFound } from "../pages/404";

export const GuestRouter = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route key={1} path="/" exact>
          <Home />
        </Route>
        <Route key={2} path="/menus" exact>
          <Menus />
        </Route>
        <Route key={3} path="/reservation" exact>
          <Reservation />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};
