import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { NotFound } from "../pages/404";
import { Login } from "../pages/login";
import { Restaurants } from "../pages/restaurants";

export const LoggedOutRouter = () => {
  return (
    <Router>
      <Switch>
        <Route key={1} path="/" exact>
          <Restaurants />
        </Route>
        <Route key={2} path="/login" exact>
          <Login />
        </Route>
        <Route key={3}>
          <NotFound />
        </Route>
      </Switch>
    </Router>
  );
};
