import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { useMe } from "../../../hooks/useMe";
import {
  getAllReservations,
  getAllReservationsVariables,
  getAllReservations_getAllReservations_reservations,
} from "../../../__generated__/getAllReservations";
import { ReservationState } from "../../../__generated__/globalTypes";
import {
  updateReservation,
  updateReservationVariables,
} from "../../../__generated__/updateReservation";

const RESERVATIONS_QUERY = gql`
  query getAllReservations($input: GetAllReservationsInput!) {
    getAllReservations(input: $input) {
      ok
      error
      reservations {
        id
        phoneNumber
        peopleNum
        state
        isQue
        createdTime
      }
    }
  }
`;

export const UPDATE_RESERVATION_MUTATION = gql`
  mutation updateReservation($input: UpdateReservationInput!) {
    updateReservation(input: $input) {
      ok
      error
      reservation {
        id
        created_at
        phoneNumber
        peopleNum
        state
        isQue
      }
    }
  }
`;

export const customTextState = (text: string) => {
  if (text === ReservationState.Created) return "현장예약";
  if (text === ReservationState.Listening) return "알림대기중";
  if (text === ReservationState.Alarmed) return "전송완료";
  if (text === ReservationState.Confirmed) return "알림확인";
  if (text === ReservationState.Done) return "완료됨";
};

export const QueReservations: React.FC = () => {
  const client = useApolloClient();
  const { data: userData } = useMe();
  const [clicked, setClicked] = useState("");

  let submittedState: ReservationState;

  const { data, loading, refetch } = useQuery<
    getAllReservations,
    getAllReservationsVariables
  >(RESERVATIONS_QUERY, {
    variables: {
      input: {
        isQue: true,
      },
    },
  });

  useEffect(() => {
    setInterval(() => {
      console.log("dd");
      refetch();
    }, 10000);
  }, []);

  const onCompleted = (updatedData: updateReservation) => {
    const {
      updateReservation: { ok, reservation },
    } = updatedData;

    if (ok && reservation) {
      const queryResult = client.readQuery({
        query: RESERVATIONS_QUERY,
        variables: { input: { isQue: true } },
      });
      console.log(queryResult);
      const filteredQues: getAllReservations_getAllReservations_reservations[] = queryResult.getAllReservations.reservations.filter(
        (i: getAllReservations_getAllReservations_reservations) =>
          i.id + "" !== reservation.id
      );
      const [
        prevQue,
      ]: getAllReservations_getAllReservations_reservations[] = queryResult.getAllReservations.reservations.filter(
        (i: getAllReservations_getAllReservations_reservations) =>
          i.id + "" === reservation.id
      );

      const updatedQue = { ...prevQue, ...reservation };
      console.log(reservation);
      console.log(prevQue);
      console.log(filteredQues);
      client.writeQuery({
        query: RESERVATIONS_QUERY,
        data: {
          getAllReservations: {
            ...queryResult.getAllReservations,
            reservations: [
              {
                ...updatedQue,
              },
            ],
          },
        },
        variables: {
          input: { inQue: true },
        },
      });
      console.log("Query is writed", updatedQue);
    }
  };

  const [updateReservation, { loading: updateLoading }] = useMutation<
    updateReservation,
    updateReservationVariables
  >(UPDATE_RESERVATION_MUTATION, { onCompleted });

  const checkDoubleClicked = (id: string, state: string) => {
    console.log(id);
    if (clicked === id) {
      setClicked("");
      submitAlarm(id, state);
    } else setClicked(id);
  };

  const submitAlarm = (id: string, state: string) => {
    if (state === ReservationState.Listening) {
      submittedState = ReservationState.Alarmed;
    } else {
      submittedState = ReservationState.Done;
    }

    updateReservation({
      variables: {
        input: {
          id,
          state: submittedState,
          restaurantId: userData?.me.restaurant?.id!,
        },
      },
    });
  };

  if (loading === true)
    return (
      <React.Fragment>
        <h1>Loading....</h1>
      </React.Fragment>
    );

  const calcTimesForMin = (time: number) => {
    return Math.floor((Date.now() / 1000 - time) / 60);
  };

  return (
    <React.Fragment>
      {!loading &&
        data!.getAllReservations.reservations!.map((reservation) => (
          <div
            key={reservation.id}
            className="px-4 justify-between group border-4  bg-gray-400 text-lg md:text-xl font-black p-2 m-2 flex iteams-center text-center hover:border-blue-400  bg-opacity-80 font-sans rounded-2xl"
          >
            <div className="flex-1">
              전화번호 <br />
              <span className="text-black text-opacity-0 group-hover:text-opacity-100">
                {reservation.phoneNumber}
              </span>
            </div>
            <div className="flex-1  border-l-2 border-r-2 mr-3 border-gray-600">
              {reservation.peopleNum}명,{" "}
              {calcTimesForMin(reservation.createdTime)}분 전
              <br />
              {customTextState(reservation.state)}
            </div>

            <div className="flex  justify-center">
              <button
                onClick={() => {
                  checkDoubleClicked(reservation.id, reservation.state);
                }}
                className={`flex-1  font-black h-full w-20 px-4 rounded-lg ${
                  reservation.state === "Confirmed"
                    ? "bg-gray-600"
                    : reservation.state === "Listening"
                    ? "bg-red-500"
                    : reservation.state === ReservationState.Done
                    ? "bg-black"
                    : "bg-yellow-500"
                } hover:bg-green-700 ${
                  clicked === reservation.id &&
                  reservation.state !== ReservationState.Done
                    ? "bg-gradient-to-l from-indigo-400  to-red-800 "
                    : updateLoading
                    ? "bg-black"
                    : ""
                } transition-all `}
              >
                {clicked === reservation.id
                  ? `Sure?`
                  : reservation.state === "Listening"
                  ? "SMS"
                  : reservation.state === "Confirmed"
                  ? "완료"
                  : "예약취소"}
              </button>
            </div>
          </div>
        ))}
    </React.Fragment>
  );
};
