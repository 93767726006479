/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ReservationState {
  Alarmed = "Alarmed",
  Confirmed = "Confirmed",
  Created = "Created",
  Done = "Done",
  Listening = "Listening",
}

export enum UserRole {
  Client = "Client",
  Guest = "Guest",
  Owner = "Owner",
}

export interface CreateReservationInput {
  phoneNumber: string;
  peopleNum: number;
  restaurantId: string;
  isOnYard: boolean;
}

export interface GetAllReservationsInput {
  phoneNumber?: string | null;
  peopleNum?: number | null;
  state?: ReservationState | null;
  isQue?: boolean | null;
  restaurantId?: string | null;
}

export interface GetReservationInput {
  id?: string | null;
  phoneNumber?: string | null;
}

export interface LoginInput {
  email: string;
  password: string;
}

export interface UpdateReservationInput {
  id: string;
  state: ReservationState;
  restaurantId: string;
  phoneNumber?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
