import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Dashboard } from "../pages/owners/dashboard";
import { Roles } from "../pages/owners/roles";
import { Walkin } from "../pages/owners/walkin";
import { NotFound } from "../pages/404";

export const LoggedInRouter = () => {
  return (
    <Router>
      <Switch>
        <Route key={1} path="/dashboard" exact>
          <Dashboard />
        </Route>
        <Route key={2} path="/reservation" exact>
          <Walkin />
        </Route>
        <Route key={3} path="/" exact>
          <Roles />
        </Route>
        <Route>
          <NotFound key={4} />
        </Route>
      </Switch>
    </Router>
  );
};
