import React from "react";
import { QueReservations } from "./dashboard/que-reservations";
import { SAMPLE_IMAGE_2 } from "../../images/images";
import { Link } from "react-router-dom";

export const Dashboard: React.FC = () => {
  return (
    <div
      className="h-screen w-screen bg-center p-2 md:p-6 grid gap-2 md:gap-6 grid-cols-2 md:grid-cols-5 items-center justify-center text-xl md:text-2xl overflow-y-scroll"
      style={{ backgroundImage: `url(${SAMPLE_IMAGE_2})` }}
    >
      <div className="row-span-3 col-span-2 md:col-span-3 w-full h-full border-2 overflow-y-scroll">
        <div className="bg-gray-800 text-white font-black pl-2 py-1 sticky top-0">
          Reservation
        </div>
        <QueReservations />
      </div>
      <div className="col-span-2 w-full h-full bg-red-100 flex md:flex-col">
        <div className="flex-none bg-gray-800 text-white font-black p-2 md:pl-2 md:py-1">
          Que Status
        </div>
        2. 예약 대기열 총수 및 예상 시간
      </div>
      <div className="col-span-2 w-full h-full bg-red-100 flex md:flex-col">
        <div className="flex-none bg-gray-800 text-white font-black p-2 md:pl-2 md:py-1">
          Promotion
        </div>
        3. 프로모션
      </div>
      <div className="w-full h-full bg-red-100 flex  md:flex-col">
        <div className="flex-none bg-gray-800 text-white font-black p-2 md:pl-2 md:py-1">
          Open
        </div>
        4. 가게상태
      </div>
      <div className="w-full h-full bg-red-100 flex md:flex-col">
        <div className="flex-none bg-gray-800 text-white font-black p-2 md:pl-2 md:py-1">
          Logout
        </div>{" "}
        <Link className="flex-1 bg-gray-500 text-center text-xl" to="/">
          돌아가기
        </Link>
      </div>
    </div>
  );
};
