import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
  split,
} from "@apollo/client";
import {
  LOCALSTORAGE_WHAT_RESERVATION,
  LOCALSTORAGE_TOKEN,
  LOCALSTORAGE_WHAT_RESTAURANT,
} from "./constants";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";

const token = localStorage.getItem(LOCALSTORAGE_TOKEN);
const restaurant = localStorage.getItem(LOCALSTORAGE_WHAT_RESTAURANT);
const reservation = localStorage.getItem(LOCALSTORAGE_WHAT_RESERVATION);
export const authTokenVar = makeVar(token);
export const whatRestaurantVar = makeVar(restaurant);
export const reservationVar = makeVar(reservation);

export const isGuestVar = makeVar(Boolean(restaurant));
export const isLoggedInVar = makeVar(Boolean(token));

const wsLink = new WebSocketLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "wss://moomyung-backends.herokuapp.com/graphql"
      : `ws://localhost:4000/graphql`,
  options: {
    reconnect: true,
    connectionParams: {
      "x-jwt": authTokenVar() || "",
    },
  },
});

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://moomyung-backends.herokuapp.com/graphql"
      : "http://localhost:4000/graphql",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-jwt": authTokenVar() ?? "",
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

export const client = new ApolloClient({
  link: splitLink,
  uri:
    process.env.NODE_ENV === "production"
      ? "https://moomyung-backends.herokuapp.com/graphql"
      : "http://localhost:4000/graphql",
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          isLoggedIn: {
            read() {
              return isLoggedInVar();
            },
          },
          isGuest: {
            read() {
              return isGuestVar();
            },
          },
          whatRestaurant: {
            read() {
              return whatRestaurantVar();
            },
          },
          whatReservation: {
            read() {
              return reservationVar();
            },
          },
        },
      },
    },
  }),
});
