import React from "react";
import { useHistory } from "react-router";
import { isGuestVar } from "../apollo";

export const Home: React.FC = () => {
  const history = useHistory();

  return (
    <div className="items-center justify-center">
      <h1 className=" pt-20 text-2xl">Home</h1>
      <button
        onClick={() => {
          history.push("/");
          isGuestVar(false);
        }}
      >
        초기화면으로
      </button>
    </div>
  );
};
