import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { authTokenVar, isLoggedInVar } from "../apollo";
import { LOCALSTORAGE_TOKEN } from "../constants";
import {
  loginMutation,
  loginMutationVariables,
} from "../__generated__/loginMutation";

const LOGIN_MUTATION = gql`
  mutation loginMutation($loginInput: LoginInput!) {
    login(input: $loginInput) {
      ok
      error
      token
    }
  }
`;

interface IFormInputs {
  email: string;
  password: string;
}

export const Login = () => {
  const {
    register,
    getValues,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInputs>({
    mode: "onChange",
  });
  const history = useHistory();

  const onCompleted = (data: loginMutation) => {
    const {
      login: { ok, token },
    } = data;
    if (ok === true && token) {
      localStorage.setItem(LOCALSTORAGE_TOKEN, token);
      history.push("/");
      authTokenVar(token);
      isLoggedInVar(true);
    }
    history.push("/");
  };

  const [loginMutation, { data: loginMutationResult, loading }] = useMutation<
    loginMutation,
    loginMutationVariables
  >(LOGIN_MUTATION, {
    onCompleted,
  });

  const onSubmit = () => {
    if (loading !== undefined) {
      const { email, password } = getValues();
      loginMutation({
        variables: {
          loginInput: {
            email,
            password,
          },
        },
      });
    }
  };

  return (
    <div className="pt-12 h-screen flex flex-col w-screen justify-center items-center bg-gray-400 text-white text-xl ">
      <form
        className="flex flex-col mx-8 p-8 bg-gray-700 w-full md:max-w-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="text-center text-2xl ">무명 어드민 로그인</h1>
        <label className="border-b-2 pl-1 mt-6 mb-2 border-blue-300">
          Email
        </label>
        <input
          className="p-2 text-gray-900"
          type="email"
          {...register("email", { required: true })}
        />
        <label className="border-b-2 pl-1 mt-6 mb-2 border-blue-300">
          비밀번호
        </label>
        <input
          className="p-2 text-gray-900"
          type="password"
          {...register("password", {
            required: "Password is required",
          })}
        />
        <input className="mt-10 p-4 rounded-xl bg-gray-800" type="submit" />
        {/* {errors.password?.message && (
          <span className="mt-4 font-bold text-center">
            {errors.password?.message}
          </span>
        )} */}
      </form>
      <button
        className="pt-12"
        onClick={() => {
          history.push("/");
        }}
      >
        홈으로 이동
      </button>
    </div>
  );
};
