import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { useForm } from "react-hook-form";
import {
  createReservationMutation,
  createReservationMutationVariables,
} from "../../__generated__/createReservationMutation";
import { SAMPLE_IMAGE_2 } from "../../images/images";
import { Link } from "react-router-dom";

const CREATE_RESERVATION = gql`
  mutation createReservationMutation($input: CreateReservationInput!) {
    createReservation(input: $input) {
      ok
      error
      reservation {
        id
        restaurantId
        state
        createdTime
      }
    }
  }
`;

interface IForm {
  phoneNumber: string;
  isOnYard: boolean;
}

export const Walkin: React.FC = () => {
  const [createReseravtion] = useMutation<
    createReservationMutation,
    createReservationMutationVariables
  >(CREATE_RESERVATION);
  const { register, getValues, handleSubmit } = useForm<IForm>({
    mode: "onSubmit",
  });

  const onSubmit = () => {
    const { phoneNumber, isOnYard } = getValues();
    createReseravtion({
      variables: {
        input: { phoneNumber, restaurantId: "1", peopleNum: 2, isOnYard },
      },
    });
    console.log(1);
  };

  return (
    <div
      className="pt-20 h-screen w-screen flex flex-col items-center  bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${SAMPLE_IMAGE_2})` }}
    >
      <form
        className="items-center flex flex-col w-full px-8 py-4"
        onSubmit={handleSubmit(onSubmit)}
      >
        <label className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
          전화번호를 입력하세요.
        </label>
        <input
          className="p-2 text-gray-900 w-full block"
          defaultValue={"010"}
          type="number"
          {...register("phoneNumber", {
            required: true,
            minLength: 10,
            maxLength: 11,
          })}
        />
        <label className="border-b-2 pl-1 mt-6 mb-2 border-blue-300 text-xl">
          현장예약 유무 선택 (선택시 현장예약이 됩니다.)
        </label>
        <input
          className="p-2 text-gray-900 w-full rounde"
          type="checkbox"
          {...register("isOnYard")}
        />
        <input className="w-full bg-coolGray-600" type="submit" />
      </form>
      <Link className="flex-1 bg-gray-500 text-center text-xl" to="/">
        돌아가기
      </Link>
    </div>
  );
};
